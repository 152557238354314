import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { parseLds } from 'shared/regexp';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Breadcrumbs from 'components/Breadcrumbs';

import './LdsPage.scss';

const LdsPage: FC<{ data: LdsPageTypes.LdsPageSiteTypes }> = ({
  data: {
    lds: { urls, seo, body, pageName },
  },
}) => {
  const { title, keywords, description } = seo;
  const [ldsContent, setLdsContent] = useState<string[]>([]);

  useEffect(() => {
    setLdsContent(body.match(parseLds) || []);
  }, [body]);

  const [bodyParsed, titleParsed, contentParsed] = ldsContent;

  const heading = bodyParsed ? (
    <div className="lds-page__heading">
      <Typography
        variant="heading2"
        weight="medium"
        dangerouslySetInnerHTML={titleParsed.toUpperCase()}
      />
    </div>
  ) : null;

  const renderContent = bodyParsed ? (
    <Typography
      variant="paragraph1"
      dangerouslySetInnerHTML={contentParsed}
      customClass="lds-page__content"
    />
  ) : null;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Container fluid className="lds-page">
        <Breadcrumbs />
        <Container element="section">
          {heading}
          {renderContent}
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query LdsPageQuery($url: String!) {
    lds(url: { eq: $url }) {
      ...LdsPageFragment
    }
  }
`;

export default LdsPage;
